.about {
  .card-row {
    .card {
      .left_side {
        .profile-pic {
          object-fit: contain;
        }
      }
      .card-content {
        .card-details {
          width: 100%;
          p {
            padding-left: 5px;
          }
        }
      }
    }
  }
}
