.app {
  .err {
    .error {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .app {
    .err {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba(0, 0, 0, 0.754);
      height: 100vh;
      .error {
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 290px;
        height: 420px;
        border-radius: 50px;

        box-shadow: 0 0 10px rgb(0, 0, 0);
        //box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
        img {
          margin-top: -40px;
          width: 63%;
          height: 45%;
        }
        h2 {
          color: red;
          border-radius: 10px;
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
          padding: 10px;
        }
      }
    }

    .main {
      display: none;
    }
  }
}
